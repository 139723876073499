import * as React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { RichText } from 'prismic-reactjs';
import '../scss/benefitsAndImageSlice.scss';

export const BenefitsAndImage = ({ slice }) => {
  const [ img, setImg ] = React.useState(null);

  // //TODO: Find better way.
  // const queryData = useStaticQuery(
  //   graphql`{
  //           prismicRandomImage {
  //               data {
  //                 images {
  //                   image {
  //                     fixed(width: 575) {
  //                       src
  //                     }
  //                     alt
  //                   }
  //                 }
  //               }
  //           }
  //       }
  //   `);

  React.useEffect(() => {
    const randomImgData = slice.primary.random_image.document.data.images;

    setImg(randomImgData[Math.floor(Math.random() * randomImgData.length)]);
  }, [ slice ]);

  return (
    <section className={`benefits-and-image`}>
      <RichText render={slice.primary.heading.raw} />
      <div className={`benefits-and-image-grid`}>
        <GatsbyImage
          className='benefit-img'
          image={getImage(img?.image)}
          alt={img?.image.alt ?? ''}
          placeholder="blurred"
          loading="lazy"
        />
        <div className={`benefit-copy`}>
          {slice.items.map((item, index) => {
            return (
              <div className={`benefit-details`} key={`benefit-item-${index}`}>
                <img src={item.benefit_image.url} alt={item.benefit_image.alt ?? ''} />
                <RichText render={item.benefit_content.raw} />
              </div>
            );
          })}
        </div>
      </div>
      <a href={slice.primary.cta_link.url} className={`button`}
        target={slice.primary.cta_link.target}>{slice.primary.cta_text}</a>
    </section>
  );
};

export const query = graphql`
fragment TabbedSectionDataBodyBenefitsAndImage on PrismicTabbedSectionDataBodyBenefitsAndImage {
  id, slice_type

  items {
    benefit_content { raw }
    benefit_image { url, alt }
  }

  primary {
    cta_link { url, target }
    cta_text
    heading { raw }

    random_image {
      document {
        ... on PrismicRandomImage {
          _previewable
          id

          data {
            images {
              image { alt, gatsbyImageData }
            }
          }
        }
      }
    }
  }
}
`;

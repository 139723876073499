import * as React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { GlobesAnimation } from './globesAnimation';
import CtaButton from '../components/cta-button';
import logoProxi from '../images/logo-proxi.svg';
import '../scss/introducingProxiSlice.scss';

export const IntroducingProxi = ({ slice }) => {
  const animationSlice = slice.primary.animation.document;

  let animation = null;
  if (animationSlice?.type === 'globes-animation') {
    animation = ( <GlobesAnimation slice={animationSlice} /> );
  } else {
    console.warn(`Invalid animation: ${JSON.stringify(animationSlice)}`);
  }

  return (
    <section className={`content-section introducing-proxi ${animationSlice?.type}-module`}>
      <div className={`introduction-wrapper`}>
        <RichText render={slice.primary.headline.raw}/>
        <div className={`introduction-sub-wrapper`}>
          <RichText render={slice.primary.subheading.raw}/>
          <img className={`logo`} src={logoProxi} alt="Proxi" />
        </div>
      </div>
      <div className={"proxi-banner-inner"}>
        <div className={`proxi-main-wrapper`}>
          <div className={`proxi-content-wrapper`}>
            <div className={`proxi-content`}>
              <RichText render={slice.primary.content.raw}/>
              <div className={`button-wrapper`}>
                <CtaButton className={`button`} {...slice.primary} />
              </div>
            </div>
            {animation}
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
fragment PageDataBodyIntroducingProxi on PrismicPageDataBodyIntroducingProxi {
  id, slice_type

  primary {
    content { raw }
    cta_text, cta_link { raw }
    headline { raw }
    subheading { raw }

    animation {
      document {
        ... on PrismicGlobesAnimation {
          ...globesAnimation
        }
      }
    }
  }
}
`;

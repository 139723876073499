import * as React from 'react';
import { graphql, Link } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import '../scss/careersOpenPositionsSlice.scss';
import chevronIcon from '../images/icons/chevron.svg';

export const CareersOpenPositions = ({ slice }) => {
  const careerPositions = slice.items.map((item, index) => {
    if (!item?.open_positions?.document) return null;
    const { url, data } = item.open_positions.document;
    return (
      <tr className={`open-position-table-row`} key={`tr-${index}`}>
        <td className={`table-title`}>
          <Link to={url}>
            {data.title.text}
          </Link>
        </td>
        <td>{data.location}</td>
        <td>{data.position}</td>
      </tr>
    );
  });

  return (
    <section className={`careers-open-positions`}>
      <a name="open-positions" />
      <div className={`background-image`} style={{ backgroundImage: `url(${slice.primary.image.url})` }} />
      <div className={`careers-open-positions__inner`}>
        <div className={`open-position-table-wrapper`}>
          <RichText render={slice.primary.heading.raw} />
          <table className={`open-position-table`} cellPadding={`0px`} cellSpacing={`0px`} border={`0`}>
            <thead className={`open-position-table-head`}>
              <tr>
                <th>Title</th>
                <th>Location</th>
                <th>Position</th>
              </tr>
            </thead>
            <tbody className={`open-position-table-body`}>
              {careerPositions}
            </tbody>
          </table>
        </div>
        <div className={`swipe-to-view`}>
          <span>swipe to view</span>
          <hr />
          <img className={`swipe-to-view-icon`} src={chevronIcon} alt={`Left pointing icon`} />
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
fragment PageDataBodyCareersOpenPositions on PrismicPageDataBodyCareersOpenPositions {
  id, slice_type

  items {
    open_positions {
      document {
        ... on PrismicCareerPosition {
          _previewable
          url
          data {
            title { text }
            location
            position
          }
        }
      }
    }
  }

  primary {
    heading { raw }
    image { url }
  }
}
`;

import React from 'react';
import Slider from 'react-slick';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { RichText } from 'prismic-reactjs';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../scss/developmentRoadmapSlice.scss';

export const DevelopmentRoadmap = ({ slice }) => {
  const settings = {
    dots: true,
    initialSlide: slice.items.length - 1,
    infinite: false,
    slidesToShow: 1,
    mobileFirst: true,
    pauseOnHover: true,
    pauseOnFocus: true,
    zIndex: 2,
    fade: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          fade: false,
          arrows: true
        }
      }
    ]
  };

  return (
    <section className={`development-roadmap`}>

      <div className={`roadmap-header`}>
        <RichText render={slice.primary.heading.raw} />
        <RichText render={slice.primary.introduction.raw} />
      </div>

      <GatsbyImage
        className='roadmap-hero'
        image={getImage(slice.primary.end_image)}
        alt={slice.primary.end_image?.alt ?? ''}
        placeholder="blurred"
        loading="lazy"
        objectFit="contain"
      />

      <Slider {...settings} className={`slide-count-${slice.items.length}`}>
        {slice.items.map((value, index) => {
          return (
            <div className={`slide`} key={index}>
              <div className={`roadmap-wrapper`}>
                <div className={`roadmap-content`}>
                  <RichText render={value.milestone.raw} />
                </div>
              </div>
            </div>
          );
        })
        }
      </Slider>

    </section>
  );
};

export const query = graphql`
fragment PageDataBodyDevelopmentRoadmap on PrismicPageDataBodyDevelopmentRoadmap {
  id, slice_type

  primary {
    end_image { alt, gatsbyImageData }
    heading { raw }
    introduction { raw }
  }

  items {
    milestone { raw }
  }
}
`;

import * as React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import CtaButton from '../components/cta-button';
import '../scss/simpleContentSlice.scss';

export const SimpleContent = ({ slice }) => {
  return (
    <section
      className={
        `simple-content
                ${slice.primary.dark_mode === true ? `dark-mode` : ''}
                ${slice.primary.image_position === 'Right' ? 'img-right' : 'img-left'}
              `}
    >
      <div className={`simple-content__inner`}>
        {slice.primary.left_side_ornament &&
          <img
            className="ornament"
            src={slice.primary.left_side_ornament.url}
            alt={slice.primary.left_side_ornament.alt ?? ''}
          />
        }
        {slice.primary.headline &&
          <div className={`headline-wrapper`}>
            <RichText render={slice.primary.headline.raw} />
          </div>
        }
        <div className={`hero-img`}>
          <img src={slice.primary.image.url} alt={slice.primary.image.alt ?? ''} />
        </div>
        <div className={`hero-copy`}>
          <RichText render={slice.primary.content.raw} />
          <CtaButton {...slice.primary} className="button" />
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
fragment PageDataBodySimpleContent on PrismicPageDataBodySimpleContent {
  id, slice_type

  primary {
    content { raw }
    cta_text, cta_link { raw }
    dark_mode
    headline { raw }
    image { alt, url }
    left_side_ornament { url ,alt }
  }
}

fragment TabbedSectionDataBodySimpleContent on PrismicTabbedSectionDataBodySimpleContent {
  id, slice_type

  primary {
    content { raw }
    cta_text, cta_link { raw }
    dark_mode
    image_position
    headline { raw }
    image { alt, url }
  }
}
`;

import React from 'react';
import { graphql } from 'gatsby';
import '../scss/embed.scss';

export const Embed = ({ slice }) => {
  if(slice != null && slice.primary != null && slice.primary.embed != null &&  slice.primary.embed.html != null)
  {
    return (
      <section
        className={`article-content-section article-embed`}
        dangerouslySetInnerHTML={{ __html: slice.primary.embed.html }}
      />
    );
  }
  
  return (
    <section
      className={`article-content-section article-embed`}
    />
  );
};

export const query = graphql`
fragment PageDataBodyEmbed on PrismicPageDataBodyEmbed {
  id, slice_type
  primary {
    embed { html }
  }
}

fragment ArticleDataBodyEmbed on PrismicArticleDataBodyEmbed {
  id, slice_type

  primary {
    embed { html }
  }
}
`;

import * as React from 'react'
import { graphql } from 'gatsby'
import {RichText} from 'prismic-reactjs';
import '../scss/featuredBlocksSlice.scss';

export const FeaturesBlocks = ({ slice }) => {
    return (
        <section className={`features-blocks ${slice.primary.dark_mode === true ? `dark-mode` : ''}`} >
            <div className="feature-details flex-column">
                <div className="feature-details-wrapper">
                    <RichText render={slice.primary.heading.raw} />
                    <div className={`build-share-explore`}>
                        {slice.items.map((item, index) => {
                            return (
                                <div key={index} className={`item-wrapper flex-33`}>
                                    <div className={`flex-row align-items-center`}>
                                        <img src={item.icon.url} alt={item.icon.alt ?? ''} />
                                        <div className={`iconlabel flex-66`}>
                                            <RichText render={item.label.raw} />
                                        </div>
                                    </div>
                                    <RichText render={item.content.raw} />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </section>
    )
}

export const query = graphql`
fragment PageDataBodyFeaturesBlocks on PrismicPageDataBodyFeaturesBlocks {
  id, slice_type

  items {
    content { raw }
    icon { alt, url }
    label { raw }
  }

  primary {
    dark_mode
    heading { raw }
  }
}
fragment TabbedSectionDataBodyFeaturesBlocks on PrismicTabbedSectionDataBodyFeaturesBlocks {
  id, slice_type

  items {
    content { raw }
    icon { alt, url }
    label { raw }
  }

  primary {
    dark_mode
    heading { raw }
  }
}
`;

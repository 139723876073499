import * as React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import SliceZone from '../components/SliceZone';

function TabPanel (props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>{children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps (index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`
  };
}

const useStyles = makeStyles(() => ({
  root: {
    margin: '0 -1.125rem',
    "@media (min-width: 786px)": {
      margin: '0 -6.5rem'
    },
    "& .MuiBox-root": {
      padding: 0,
      margin: '0 1.125rem',
      "@media (min-width: 786px)": {
        margin: '0 6.5rem'
      }
    }
  },
  button: {
    color: '#000',
    fontFamily: 'Rajdhani',
    fontSize: '1.125rem',
    fontStyle: 'normal',
    fontWeight: 'bold',
    letterSpacing: '0.92',
    lineHeight: '1.33',
    textDecoration: 'underline',
    textTransform: 'initial',
    width: '50%',
    "@media (min-width: 786px)": {
      fontSize: '2.875rem',
      width: '50vw',
      minWidth: '24rem'
    },
    '&.Mui-selected': {
      color: '#e59c14',
      textDecoration: 'none'
    }
  },
  indicator: {
    backgroundImage: "linear-gradient(to right, #e58d14, #ffd900)",
    height: '.5rem'
  },
  tabContent: {
    fontFamily: 'Rajdhani',
    padding: 0
  }
}));

export const TabbedSection = ({ slice }) => {
  const classes = useStyles();
  const [ value, setValue ] = React.useState(0);

  const handleChange = (ev, newValue) => {
    setValue(newValue);
  };

  const items = slice.items.filter(item => item.tabbed_section.document);

  const tabs = items.map((item, index) => {
    return (
      <Tab
        label={item.tabbed_section.document.data.tab_title.text}
        {...a11yProps(0)}
        className={classes.button}
        key={index}/>
    );
  });

  const tabPanels = items.map((item, index) => {
    return (
      <TabPanel value={value} index={index} className={classes.tabContent}
        key={`tabpanel-${index}`}>
        <SliceZone sliceZone={item.tabbed_section.document.data.body}/>
      </TabPanel>
    );
  });

  return (
    <section className={`tabbed-section`}>
      <div className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          variant="fullWidth"
          TabIndicatorProps={{ className: classes.indicator }}
        >
          {tabs}
        </Tabs>
        {tabPanels}
      </div>
    </section>
  );
};

export const query = graphql`
fragment PageDataBodyTabbedSection on PrismicPageDataBodyTabbedSection {
  id, slice_type

  items {
    tabbed_section {
      document {
        ... on PrismicTabbedSection {
          _previewable

          data {
            tab_title { text }
            body {
              ... on PrismicTabbedSectionDataBodyBenefitsAndImage {
                ...TabbedSectionDataBodyBenefitsAndImage
              }
              ... on PrismicTabbedSectionDataBodyFeaturesBlocks {
                ...TabbedSectionDataBodyFeaturesBlocks
              }
              ... on PrismicTabbedSectionDataBodySimpleContent {
                ...TabbedSectionDataBodySimpleContent
              }
            }
          }
        }
      }
    }
  }
}
`;

import React, { useState } from 'react';
import Slider from 'react-slick';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import TeamProfile from '../components/TeamProfile';
import '../scss/teamProfileSlice.scss';

export const TheTeam = ({ slice }) => {
  const [ slide, setSlide ] = useState(0);

  const handleAfterChange = index => {
    setSlide(index);
  };

  const settings = {
    dots: false,
    slidesToShow: 1,
    zIndex: 2,
    afterChange: handleAfterChange,
    responsive: [
      {
        breakpoint: 9999,
        settings: "unslick"
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false
        }
      }
    ]
  };

  return (
    <section className={`the-team`}>
      <div className={`team-intro`}>
        <RichText render={slice.primary.content.raw} />
      </div>
      <Slider className={`team-profile-grid flex-row wrap justify-content-between`} {...settings} >
        {slice.items.map((row, index) => {
          return (
            <TeamProfile key={index} row={row} />
          );
        })}
      </Slider>
      <div className={`profileGridSlideCount`}>
        <strong>{slide + 1}</strong> of {slice.items.length}
      </div>
    </section>
  );
};

export const query = graphql`
fragment PageDataBodyTheTeam on PrismicPageDataBodyTheTeam {
  id, slice_type

  primary {
    content { raw }
  }

  items {
    person_title
    bio { raw }
    image { alt, url }
    name { raw }
  }
}
`;

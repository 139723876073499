import React, { useRef, useState, useCallback, useEffect } from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { LiquidAndLogoAnimat } from './liquidAndLogoAnimat';
import { GlobesAnimation } from './globesAnimation';
import '../scss/headerSlice.scss';

export function Header ({ slice }) {
  const sliderRef = useRef(null);
  const [ activeSliderIndex, setActiveSliderIndex ] = useState(0);

  const animationSlice = slice.primary.animation.document;

  let animation = null;
  switch (animationSlice?.type) {
    case 'liquid_and_logo_animat':
      animation = ( <LiquidAndLogoAnimat slice={animationSlice} /> );
      break;
    case "globes-animation":
      animation = ( <GlobesAnimation slice={animationSlice} /> );
      break;
    default:
      console.warn(`Invalid animation: ${JSON.stringify(animationSlice)}`);
  }

  const sliderContent = slice.items.map((item, index) => {
    return (
      <div key={index} className={`slide`}>
        <RichText render={item.content.raw} />
      </div>
    );
  });

  const handleSliderDotClick = useCallback((index, ev) => {
    ev.preventDefault();

    setActiveSliderIndex(index);

    const slides = sliderRef.current.querySelectorAll('.slide');

    sliderRef.current.scrollTo({ left: slides[index].offsetLeft, behavior: 'smooth' });
  }, []);

  const sliderDots = slice.items.length === 1 ? null : slice.items.map((item, index) => {
    return (
      <button
        className={`slider-dot ${index === activeSliderIndex ? 'active' : ''}`}
        key={index}
        onClick={handleSliderDotClick.bind(this, index)}
      >
        <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
          <circle cx="7" cy="7" r="7" fill="#000000" strokeWidth="0" />
        </svg>
      </button>
    );
  });

  useEffect(() => {
    if (!sliderRef.current) return;

    const sliderEl = sliderRef.current;

    let timeout;
    function handleScroll () {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;

        const slides = Array.from(sliderEl.querySelectorAll('.slide')).map((s, index) => {
          return { index, diff: Math.abs(s.offsetLeft - sliderEl.scrollLeft) };
        }).sort((a, b) => a.diff - b.diff);

        setActiveSliderIndex(slides[0].index);
      }, 50);
    }

    sliderEl.addEventListener('scroll', handleScroll);

    return () => {
      if (!sliderEl) return;
      sliderEl.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section className={`header-banner ${animationSlice?.type}-module`}>
      <div className={"header-banner-inner"}>
        <div className={`header-banner-content`}>
          <div className="header-banner-slider" ref={sliderRef}>
            {sliderContent}
          </div>
        </div>
        {animation}
      </div>
      <div className="slider-dots">{sliderDots}</div>
    </section>
  );
}

export const query = graphql`
fragment PageDataBodyHeader on PrismicPageDataBodyHeader {
  id, slice_type

  primary {
    animation {
      document {
        ... on PrismicGlobesAnimation {
          ...globesAnimation
        }
        ... on PrismicLiquidAndLogoAnimat {
          ...liquidAndLogoAnimation
        }
      }
    }
  }

  items {
    content { raw }
  }
}
`;

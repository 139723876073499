import React, { Component } from 'react';

const SITE_KEY = '6LdLD_gbAAAAAF3g_rkjrCOyEZa3ONMQLvdiKOxs';

export default class ReCaptcha extends Component {
    componentDidMount () {
        const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id);

            if (!isScriptExist) {
                let script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;

                script.onload = () => {
                    if (callback) callback();
                };
                document.body.appendChild(script);
            }

            if (isScriptExist && callback) callback();
        };

        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`);
    }

    render () {
        return ( <>{this.props.children}</> );
    }
}

export function validateForSubmit (action) {
    return new Promise((resolve, reject) => {
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(SITE_KEY, { action }).then(token => {
                resolve({ 'g-recaptcha-response': token });
            }, reject);
        });
    });
}

import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import Link from '../components/link';
import '../scss/threeColumnGridWithIconsSlice.scss';

export const ThreeColumnGridWithIcons = ({ slice }) => {
  return (
    <section className={`threecolumn-module-wrapper`}>
      <RichText render={slice.primary.heading.raw} />
      <Link to={slice.primary.link_url}>{slice.primary.link_text}</Link>
      <div className={`threecolumn-module`}>
        {slice.items.map((column, index) => {
          return (
            <div className={`threecolumn-wrapper`} key={`div-${index}`}>
              <div className={`threecolumn-hero`}>
                <img src={column.icon.url} alt={column.icon.alt ?? ''}/>
                <RichText render={column.label.raw} />
              </div>
              <RichText render={column.content.raw} />
              {column.link_text && <Link to={column.link_url}>{column.link_text}</Link>}
            </div>
          );
        })}
      </div>
    </section>
  );
};

export const query = graphql`
fragment PageDataBodyThreeColumnGridWithIcons on PrismicPageDataBodyThreeColumnGridWithIcons {
  id, slice_type

  primary {
    heading { raw }
    link_url { raw }
    link_text
  }

  items {
    content { raw }
    icon { url, alt }
    label { raw }
    link_text
    link_url { raw }
  }
}
`;

/*globals process*/
import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import ReCaptcha, { validateForSubmit } from './recaptcha';
import '../../scss/contact-form.scss';

export default function ContactForm ({ data }) {
  const [ showSuccessMessage, setShowSuccessMessage ] = useState(false);
  const [ submitting, setSubmitting ] = useState(false);

  const handleSubmit = (ev) => {
    ev.preventDefault();

    const formEl = ev.target;

    if (!formEl.reportValidity()) return;

    async function _handleSubmit () {
      setSubmitting(true);

      const recaptchaData = await validateForSubmit('submit_contact_form');
      const data_ = { ...recaptchaData };

      for (let el of formEl) {
        if (!el.name?.length || !el.value?.length) continue;
        data_[el.name] = el.value;
      }

      const res = await fetch(process.env.CONTACT_FORM_ENDPOINT, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        referrerPolicy: 'no-referrer-when-downgrade',
        body: JSON.stringify(data_)
      });

      if (!res.ok) {
        setSubmitting(false);
        const errBody = await res.text();
        throw new Error(`Form submission failed ${res.status}: ${errBody}`);
      }

      setShowSuccessMessage(true);
      setSubmitting(false);
    }

    _handleSubmit().catch(console.error);
  };

  if (showSuccessMessage) {
    return (
      <div className="newsletter-signup-form category">
        <div className="success-message">Thank-you for contacting Gallium</div>
      </div>
    );
  }

  return (
    <ReCaptcha>
      <form className={`contact-us-form`} onSubmit={handleSubmit}>
        <div className={`form-field-wrapper`}>
          <div>
            <TextField
              id="contact-us-form-name"
              label="Your name"
              name="contact-us-form-name"
              required={true}
              fullWidth={true}
              type="text"
            />
            <TextField
              id="contact-us-form-email"
              label="Your email address"
              name="contact-us-form-email"
              required={true}
              fullWidth={true}
              type="email"
            />
            <TextField
              id="contact-us-form-message"
              label="Your message"
              name="contact-us-form-message"
              required={true}
              fullWidth={true}
              type="textfield"
            />
          </div>
          <div>
            { data.primary.show_company_field && data.primary.show_company_field === true &&
              <TextField
                id="contact-us-form-company"
                label="Your company"
                name="contact-us-form-company"
                required={true}
                fullWidth={true}
                type="text"
              />
            }
            { data.primary.show_publication_link_field &&
              data.primary.show_publication_link_field === true &&
              <TextField
                id="contact-us-form-link"
                label="A link to your publication"
                name="contact-us-form-link"
                fullWidth={true}
                type="text"
              />
            }
          </div>
        </div>
        <Button
          type="submit"
          variant="contained"
          color="inherit"
          disabled={submitting}
        >
                    Submit
        </Button>
      </form>
    </ReCaptcha>
  );
}

import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import useMergePrismicPreviewData from '../utils/use-merge-prismic-preview-data-fix';
import { RichText } from 'prismic-reactjs';
import EmailSignupForm from '../components/forms/EmailSignupForm';
import SocialIcons from '../components/SocialIcons';
import CustomLink from '../components/CustomLink';
import '../scss/connectModuleSlice.scss';

export const ConnectModule = ({ slice }) => {
  const data0 = useStaticQuery(graphql`
    query ConnectModuleGlobalOptionsQuery {
      prismicGlobalOptions {
        _previewable
        data {
          social_links {
            icon {
              url
              fixed(imgixParams: {height: 32, width: 32}, height: 32, width: 32) {
                src
              }
            }
            link { raw }
          }
        }
      }
    }
  `);

  const data = useMergePrismicPreviewData(data0);

  const socialLinks = data.prismicGlobalOptions.data.social_links;

  return (
    <section className={`connect-module-section`}>
      <RichText render={slice.primary.heading.raw}/>
      <div className={`connect-module`}>
        {slice.items.map((item, index) => {
          return (
            <div className={`connect-wrapper`} key={`div-${index}`}>
              <div className={`connect-hero`}>
                <img src={item.icon.url} alt={item.icon.alt ?? ''}/>
                <RichText render={item.label.raw}/>
              </div>
              <RichText render={item.content.raw} serializeHyperlink={CustomLink}/>
              { item.show_subscribe_form && item.show_subscribe_form === true &&
                <EmailSignupForm/>
              }
              { item.show_social_links && item.show_social_links === true &&
                <SocialIcons socialLinks={socialLinks}/>
              }
            </div>
          );
        })}
      </div>
    </section>
  );
};

export const query = graphql`
fragment PageDataBodyConnectModule on PrismicPageDataBodyConnectModule {
  id, slice_type

  items {
    content { raw }
    icon { alt, url }
    label { raw }
    show_social_links
    show_subscribe_form
  }

  primary {
    heading { raw }
  }
}

fragment NewsPageDataBodyConnectModule on PrismicNewsPageDataBodyConnectModule {
  id, slice_type

  items {
    content { raw }
    icon { alt, url }
    label { raw }
    show_social_links
    show_subscribe_form
  }

  primary {
    heading { raw }
  }
}
`;

import * as React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { RichText } from 'prismic-reactjs';
import CtaButton from '../components/cta-button';
import '../scss/joinTheCommunitySlice.scss';

export const JoinTheCommunity = ({ slice }) => {
  return (
    <section className={`join-the-community`}>
      <div className={`community-content-wrapper`}>
        <GatsbyImage
          className='community-hero-wrapper'
          image={getImage(slice.primary.image)}
          alt={slice.primary.image.alt ?? ''}
          loading="lazy"
          placeholder="blurred"
        />
        <div className={`community-inner-wrapper`}>
          <RichText render={slice.primary.content.raw}/>
          <div className={`creator-toolkit`}>
            {slice.items.map((item, index) => {
              return (
                <div key={`toolkit-${index}`}>
                  <div className={`img-container`}>
                    <img src={item.benefit_image.url} alt={item.benefit_image.alt ?? ''}/>
                  </div>
                  <div className={'creator-toolkit-title'}>{item.benefit_label}</div>
                </div>
              );
            })}
          </div>
          <CtaButton className={`button`} {...slice.primary} />
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
fragment PageDataBodyJoinTheCommunity on PrismicPageDataBodyJoinTheCommunity {
  id, slice_type

  items {
    benefit_image { alt, url }
    benefit_label
  }

  primary {
    content { raw }
    cta_text, cta_link { raw }
    image { alt, gatsbyImageData }
  }
}
`;

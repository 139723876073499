/*globals process*/
import React, { useState, useMemo, useEffect } from 'react';
import Slider from 'react-slick';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { Buffer } from 'buffer';
import Gx42Store from 'gx42-store';
import '../scss/contentPacksSlice.scss';

const DATE_FORMATTER = new Intl.DateTimeFormat(undefined, { dateStyle: 'long' });
const LISTING_URL_BASE = 'https://app.galliumstudios.com/gx42/listings/';

export const ContentPacks = ({ slice }) => {
  const [ listings, setListings ] = useState();

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 2,
    mobileFirst: true,
    pauseOnHover: true,
    pauseOnFocus: true,
    zIndex: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  useEffect(() => {
    fetch(process.env.CONTENT_PACKS_LISTINGS_ENDPOINT).then((res) => {
      return res.text();
    }).then((data) => {
      const buf = Buffer.from(data, 'base64');
      return Gx42Store.ListingResponse.FromFlatbuffer(buf);
    }).then((listingResponse) => setListings(listingResponse.listings));
  }, []);

  const sliderContent = useMemo(() => {
    if (!listings) return;

    return listings.map((listing, index) => {
      try {
        if (!listing.enabled) return null;

        const releaseDate = new Date(Number(listing.releaseDate * 1000n));
        const endDate = new Date(Number(listing.endDate * 1000n));
        const registrationDate = new Date(Number(listing.registrationDate * 1000n));
        const now = new Date(Date.now());
        const listingUrl = new URL(listing.uid, LISTING_URL_BASE);
        const price = Number(listing.price / 10000000000n);
        const rewardCount = listing.rewardCount;
        const title = listing.locTitle.replace(' (Registration Available)', '');

        let listingButton;

        if (listing.soldOut) {
          listingButton = (
            <a className="button disabled sold-out" disabled>
              {slice.primary.cta_sold_out_text}
            </a>
          );
        } else if (listing.endDate !== undefined && endDate < now) {
          listingButton = (
            //eslint-disable-next-line react/jsx-no-target-blank
            <a className="button sale-ended disabled" disabled>
              {slice.primary.cta_sale_ended_text}
            </a>
          );
        } else if (listing.releaseDate !== undefined && releaseDate < now) {
          listingButton = (
            //eslint-disable-next-line react/jsx-no-target-blank
            <a className="button buy-now" href={listingUrl.toString()} target="_blank">
              {slice.primary.cta_buy_text}
            </a>
          );
        } else if (listing.registrationDate !== undefined && registrationDate < now) {
          listingButton = (
            //eslint-disable-next-line react/jsx-no-target-blank
            <a className="button pre-order" href={listingUrl.toString()} target="_blank">
              {slice.primary.cta_pre_order_text}
            </a>
          );
        } else {
          listingButton = (
            <a className="button coming-soon" disabled>
              {slice.primary.cta_coming_soon_text}
            </a>
          );
        }

        return (
          <div key={index} className={`slide`}>
            <div className={`subhead01 mobile-hide`}>{listing.locTitle}</div>
            <div className={`content-packs-content`}>
              <img className={`hero`} src={listing.imageUrl} alt="" />
              <div className={`subhead01 mobile-show`}>{listing.locTitle}</div>
              <div className={`wrapper`}>
                <ul>
                  <li>
                    <span className={`label`}>Pack</span>
                    <span>{title}</span>
                  </li>
                  <li>
                    <span className={`label`}>Contains</span>
                    <span>{rewardCount} Assets</span>
                  </li>
                  <li>
                    <span className={`label`}>Price</span>
                    <span>{price.toFixed(3)} Gallium</span>
                  </li>
                  <li>
                    <span className={`label`}>Release</span>
                    <span>{DATE_FORMATTER.format(releaseDate)}</span>
                  </li>
                </ul>
                {listingButton}
              </div>
            </div>
          </div>
        );
      } catch (e) {
        console.error(`Failed to generate listing card for ${listing?.uid}: ${e.stack}`);
        return null;
      }
    });
  }, [ slice, listings ]);

  return (
    <section className={`content-packs`}>
      <RichText render={slice.primary.heading.raw}/>
      <Slider {...settings} >
        {sliderContent}
      </Slider>
    </section>
  );
};

export const query = graphql`
fragment PageDataBodyContentPacks on PrismicPageDataBodyContentPacks {
  id, slice_type

  primary {
    cta_sold_out_text
    cta_buy_text
    cta_coming_soon_text
    cta_pre_order_text
    cta_sale_ended_text
    heading { raw }
  }
}
`;

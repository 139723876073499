import * as React from 'react'
import {graphql} from 'gatsby'
import {RichText} from 'prismic-reactjs';
import CustomLink from '../components/CustomLink';
import '../scss/articleContent.scss';

export const ArticleContent = ({slice}) => {
    const paddingClass = (slice_) => {
        if (!slice_.image_page_padding) {
            return 'remove-padding';
        }
        return '';
    };

    const alignmentClass = (slice_) => {
        switch (slice_.primary.text_position) {
            case 'Middle':
                return 'center-content';
            case 'Left':
                return 'left-align-content';
            default:
                return '';
        }
    }

    return (
        <section className={`article-content-section content-section ${paddingClass(slice)} ${alignmentClass(slice)}`}>
            {Object.keys(slice.primary.image).length > 0 &&
            <div className={`article-img`}>
                <img src={slice.primary.image.url} alt={slice.primary.image.alt ?? ''}/>
            </div>
            }
            <div className={`article-content`}>
                <RichText
                    render={slice.primary.content.raw}
                    serializeHyperlink={CustomLink}
                />
            </div>
        </section>
    )
}

export const query = graphql`
fragment PageDataBodyArticleContent on PrismicPageDataBodyArticleContent {
  id, slice_type

  primary {
    content { raw }
    image { alt, url }
    image_page_padding
    text_position
  }
}

fragment ArticleDataBodyArticleContent on PrismicArticleDataBodyArticleContent {
  id, slice_type

  primary {
    content { raw }
    image { alt, url }
    image_page_padding
    text_position
  }
}
`;

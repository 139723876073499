import * as React from 'react';
import {ArticleContent} from '../slices/articleContent';
import {Embed} from '../slices/embed';
import {Header} from '../slices/header';
import {ContentPacks} from '../slices/contentPacks';
import {IntroducingProxi} from '../slices/introducingProxi';
import {JoinTheCommunity} from '../slices/joinTheCommunity';
import {DevelopmentRoadmap} from '../slices/developmentRoadmap';
import {TabbedSection} from '../slices/tabbedSection';
import {TheTeam} from '../slices/theTeam';
import {SimpleContent} from '../slices/simpleContent';
import {FeaturesBlocks} from '../slices/featuresBlocks';
import {CareersOpenPositions} from '../slices/careersOpenPositions';
import {AddressBlock} from '../slices/addressBlock';
import {ContactUsForm} from '../slices/contactUsForm';
import {ConnectModule} from '../slices/connectModule';
import {ThreeColumnGridWithIcons} from '../slices/threeColumnGridWithIcons';
import {BenefitsAndImage} from '../slices/benefitsAndImage';

export const SliceZone = ({ sliceZone }) => {
    const sliceComponents = {
        article_content: ArticleContent,
        embed: Embed,
        header: Header,
        content_packs: ContentPacks,
        introducing_proxi: IntroducingProxi,
        join_the_community: JoinTheCommunity,
        development_roadmap: DevelopmentRoadmap,
        tabbed_section: TabbedSection,
        the_team: TheTeam,
        simple_content: SimpleContent,
        features_blocks: FeaturesBlocks,
        careers_open_positions: CareersOpenPositions, 
        address_block: AddressBlock,
        contact_us_form: ContactUsForm,
        connect_module: ConnectModule,
        three_column_grid_with_icons: ThreeColumnGridWithIcons,
        benefits_and_image: BenefitsAndImage,
    };

    const sliceZoneContent = sliceZone.map((slice, index) => {
        let SliceComponent = sliceComponents[slice.slice_type];
        if (SliceComponent) {
            return <SliceComponent slice={slice} key={`slice-${index}`}/>
        }
        SliceComponent = sliceComponents[slice.type];
        if (SliceComponent) {
            return <SliceComponent slice={slice} key={`slice-${index}`}/>
        }
        return null
    })

    return <>{sliceZoneContent}</>
}

export default SliceZone
import React from "react";
import { RichText } from 'prismic-reactjs';

const TeamProfile = ({ row }) => {
  return (
    <div className={`mb-2rem`}>
      <div className={`team-image-container`}>
        <img className={`teamImage`} src={row.image.url} alt={row.image.alt ?? ''} />
      </div>
      <RichText render={row.name.raw} />
      <div className={`teamTitle`}>{row.person_title}</div>
      <div className={`profile-bio`}>
        <RichText render={row.bio.raw} />
      </div>
    </div>
  );
};

export default TeamProfile;

import React from 'react';
import { Link } from 'gatsby';
import { linkResolver } from '../utils/linkResolver';

export default function CtaButton ({ className, cta_link, cta_text }) {
  cta_link = cta_link.raw ? cta_link.raw : cta_link;

  if (cta_link.link_type === 'Document') {
    return (
      <Link
        className={className}
        to={linkResolver(cta_link)}
        target={cta_link.target}
      >
        {cta_text ?? ''}
      </Link>
    );
  }

  if (cta_link.link_type === 'Any') {
    return (
      <a
        className={className}
        href={cta_link.url ?? '#'}
        target={cta_link.target}
        rel={cta_link.target === "_blank" ? 'noreferrer' : null}
      >
        {cta_text ?? ''}
      </a>
    );
  }

  if (cta_link.link_type === 'Web') {
    return (
      <a
        className={className}
        href={cta_link.url.replace(/^http(s)?:\/\/#/, '#')}
        target={cta_link.target}
        rel={cta_link.target === "_blank" ? 'noreferrer' : null}
      >
        {cta_text ?? ''}
      </a>
    );
  }

  throw new Error(`Invalid link_type for CtaButton ${JSON.stringify({ cta_link, cta_text })}`);
}

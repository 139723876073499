import * as React from 'react'
import { graphql } from 'gatsby'
import {RichText} from 'prismic-reactjs';
import ContactForm from '../components/forms/ContactForm';
import '../scss/contactUsFormSlice.scss';

export const ContactUsForm = ({ slice }) => {
    return (
        <section className={`contact-us-form-wrapper`}>
            <div className={`contact-us-form-hero`}>
                <img src={slice.primary.image.url} alt={slice.primary.image.alt ?? ''}/>
            </div>
            <div className={`contact-us-form-content`}>
                <RichText render={slice.primary.heading.raw} />
                <ContactForm data={slice}/>
            </div>
        </section>
    )
}

export const query = graphql`
fragment PageDataBodyContactUsForm on PrismicPageDataBodyContactUsForm {
  id, slice_type

  primary {
    heading { raw }
    image { alt, url }
    show_company_field
    show_publication_link_field
  }
}
`;

import * as React from 'react'
import { graphql } from 'gatsby'
import {RichText} from 'prismic-reactjs';
import '../scss/addressBlockSlice.scss';

export const AddressBlock = ({ slice }) => {
    return (
        <section className={`address-block`}>
            <RichText render={slice.primary.heading.raw} />
            {slice.items.map((item, index) => {
                return (
                    <div className={`contact`} key={`contact-${index}`}>
                        <img src={item.icon.url} alt={item.icon.alt ?? ''}/>
                        <address>
                            <a href={item.link.url}
                               target={item.link.target} aria-label={item.link.target === '_blank' ? `Opens in a' +
                                    ' new window` : ''}><RichText render={item.content.raw} /></a>
                        </address>
                    </div>
                )
            })}
        </section>
    )
}

export const query = graphql`
fragment PageDataBodyAddressBlock on PrismicPageDataBodyAddressBlock {
  id, slice_type

  items {
    content { raw }
    icon { alt, url}
    link { url, target }
  }

  primary {
    heading { raw }
  }
}
`;

import * as React from 'react';
import { graphql } from 'gatsby';
import gsap, { Power1 } from 'gsap';
import '../scss/globesAnimationSlice.scss';

export const GlobesAnimation = ({ slice }) => {
  const globesRefs = React.useRef([]);
  const heroRef = React.useRef(null);
  const [ tl ] = React.useState(gsap.timeline({ paused: true, repeat: -1 }));
  const [ heroTl ] = React.useState(gsap.timeline({ paused: true, repeat: -1 }));

  React.useEffect(() => {
    heroTl.to(heroRef.current, { duration: 5, y: '3%', ease: Power1.easeInOut })
      .to(heroRef.current, { duration: 5, y: 0, ease: Power1.easeInOut });

    globesRefs.current.forEach((globe, i) => {
      tl.addLabel(`globe-${i}`, '<');
    });

    globesRefs.current.forEach((globe, i) => {
      const y = i === 4 ? '10%' : '-7%';
      const animation = gsap.to(globe, { duration: 5, y, ease: Power1.easeInOut });
      tl.add(animation, `globe-${i}`);
    });

    globesRefs.current.forEach((globe, i) => {
      const animation = gsap.to(globe, { duration: 5, y: 0, ease: Power1.easeInOut });
      tl.add(animation, `globe-${i}+=5`);
    });

    tl.play();
    heroTl.play();

    return () => {
      tl.kill();
      heroTl.kill();
    };
  }, [ tl, heroTl ]);

  return (
    <div className={"globes-animation"}>
      <img
        ref={heroRef}
        className={`hero`}
        src={slice.data.big_globe.url}
        alt={slice.data.big_globe.alt ?? ''}
      />

      {slice.data.globes.map((item, index) => {
        return (
          <div
            ref={el => globesRefs.current.push(el)}
            key={`globe-${index}`}
            className={`globe globe-${index}`}
          >
            {item.accessory.url !== '' &&
              <img
                className={`accessory`}
                src={item.accessory.url}
                alt={item.accessory.alt ?? ''}
              />
            }
            <img className={`globeimg`} src={item.globe.url} alt={item.globe.alt ?? ''} />
          </div>
        );
      })}
    </div>
  );
};

export const query = graphql`
fragment globesAnimation on PrismicGlobesAnimation {
  _previewable
  type

  data {
    big_globe { alt, url }

    globes {
      accessory { alt, url }
      globe { alt, url }
    }
  }
}
`;

import React from 'react';
import { Link } from 'gatsby';
import { linkResolver } from '../utils/linkResolver';

const CustomLink = (type, element, content, children, index) => {
  if (element.data.link_type === 'Document') {
    return (
      <Link id={element.data.id} to={linkResolver(element.data)} key={index}>
        {content}
      </Link>
    );
  }

  if (element.data.link_type === 'Web') {
    return (
      <a
        id={element.data.id}
        href={element.data.url}
        key={index}
        target={element.data.target}
        rel={element.data.target === "_blank" ? 'noreferrer' : null}
      >
        {content}
      </a>
    );
  }

  return null;
};

export default CustomLink;
